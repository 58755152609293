import React, { useEffect, useRef } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import EuropeFlag from 'images/europe-flag-red.svg'
import Close from './close'
import './index.scss'

function Modal({ show, isSaveButtonLocked, onConfirm }) {
  const ref = useRef()

  useEffect(() => {
    ref.current.focus()
    window.setTimeout(onConfirm, 2500)
  }, [isSaveButtonLocked])

  return (
    <div
      ref={ref}
      className={classNames('modal', {
        'modal--hidden': !show,
      })}
    >
      <div className="modal__inner" onClick={onConfirm}>
        <Close />
        <div className="modal__img-wrapper">
          <img src={EuropeFlag} alt="Europe Flag" className="modal__img" />
        </div>
        <div className="modal__text">
          <h2 className="modal__headline">
            Congratulations!
          </h2>
          <p className="modal__p"><strong>You are now an official member of the European family.</strong></p>
          <p className="modal__p">Please find your new identity in your downloads. Feel free to share your new freedom on your social media channels.</p>
          <p className="modal__p">Baba Bussis – Yours Bilderbuchs</p>
        </div>
      </div>
    </div>
  )
}

Modal.propTypes = {
  show: PropTypes.bool,
  isSaveButtonLocked: PropTypes.bool,
  onConfirm: PropTypes.func,
}

Modal.defaultProps = {
  show: true,
  isSaveButtonLocked: true,
  onConfirm() { },
}

export default Modal
