import * as THREE from 'three'

import {
  hashCode,
  leftPad,
  objectAssign,
  smallestPowerOfTwoOver,
} from './helpers'

import passportBlank from 'images/passport-blank.jpg'

const MAX_EDGE_LENGTH = 4096

// Get the device pixel ratio, falling back to 1
const DPR = (typeof window !== 'undefined' && window.devicePixelRatio) || 1

const DEFAULT_SURNAME = '-'
const DEFAULT_GIVEN_NAMES = '-'
const DEFAULT_BIRTH_DATE = '----'
const DEFAULT_NATIONALITY = 'European'
const DEFAULT_GENDER = '–'

const MAX_BOTTOM_LINE_LENGTH = 64
const ANGLE_BRACKETS =
  '<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<'

function rightPadAngles(string) {
  let s = string + ANGLE_BRACKETS
  return s.slice(0, MAX_BOTTOM_LINE_LENGTH)
}

// OPTIONS MUST CONTAIN:
// { width, height, surname, givenNames, birthDate, number, gender, onInitialRender }
// width & height must already be DPR adjusted
// OPTIONS MAY CONTAIN:
// { portrait }
class Passport2dRenderer {
  constructor(options = {}) {
    this.options = options

    this.canvas = document.createElement('canvas')
    this.setCanvasDimensions(options.width * DPR)
    this.ctx = this.canvas.getContext('2d')

    this.isPassportImageLoaded = false
    this.passportImageLoadHandler = this.passportImageLoadHandler.bind(this)
    this.passportImage = new Image()
    this.passportImage.onload = this.passportImageLoadHandler
    this.passportImage.src = passportBlank

    this.map = null
  }

  setCanvasDimensions(width) {
    let edgeLength = smallestPowerOfTwoOver(width)
    if (edgeLength > MAX_EDGE_LENGTH) {
      edgeLength = MAX_EDGE_LENGTH
    }
    this.canvas.width = edgeLength
    this.canvas.height = edgeLength
  }

  drawPassport() {
    let imageWidth = this.passportImage.naturalWidth
    let imageHeight = this.passportImage.naturalHeight
    let aspect = imageWidth / imageHeight
    let width = this.canvas.width
    this.ctx.drawImage(this.passportImage, 0, 0, width, width / aspect)

    // HERE GO ALL THE PARAMETERS FOR THE FONT RENDERING
    let fontSize = width * 0.025
    let leftRowLeft = width * 0.38
    let surnameLeft = leftRowLeft
    let surnameTop = width * 0.22
    let givenNamesLeft = leftRowLeft
    let givenNamesTop = width * 0.3
    let nationalityLeft = leftRowLeft
    let nationalityTop = width * 0.38
    let birthDateLeft = leftRowLeft
    let birthDateTop = width * 0.46
    let numberLeft = width * 0.64
    let numberTop = birthDateTop
    let bottomLine1Left = width * 0.02
    let bottomLine1Top = width * 0.572
    let bottomLine2Left = width * 0.02
    let bottomLine2Top = width * 0.605
    let genderLeft = numberLeft
    let genderTop = surnameTop
    let issueDateLeft = numberLeft
    let issueDateTop = givenNamesTop

    this.ctx.font = `${fontSize}px 'Courier New'`

    let surname = this.options.surname
    if (surname === '') surname = DEFAULT_SURNAME
    let givenNames = this.options.givenNames
    if (givenNames === '') givenNames = DEFAULT_GIVEN_NAMES
    let birthDate = this.options.birthDate
    if (birthDate === '') birthDate = DEFAULT_BIRTH_DATE
    let gender = this.options.gender
    if (gender === '') gender = DEFAULT_GENDER
    let nationality = DEFAULT_NATIONALITY
    let _d = new Date()
    let _issueDate = {
      year: _d.getFullYear().toString(),
      month: leftPad((_d.getMonth() + 1).toString(), 2, '0'),
      day: leftPad(_d.getDate().toString(), 2, '0'),
    }
    let issueDate = `${_issueDate.day}.${_issueDate.month}.${_issueDate.year}`
    let [bottomLine1, bottomLine2] = Passport2dRenderer.generateBottomLines(
      surname,
      givenNames,
      birthDate,
      this.options.number,
      _issueDate
    )

    this.ctx.fillText(surname, surnameLeft, surnameTop)
    this.ctx.fillText(givenNames, givenNamesLeft, givenNamesTop)
    this.ctx.fillText(nationality, nationalityLeft, nationalityTop)
    this.ctx.fillText(birthDate, birthDateLeft, birthDateTop)
    this.ctx.fillText(this.options.number, numberLeft, numberTop)
    this.ctx.fillText(bottomLine1, bottomLine1Left, bottomLine1Top)
    this.ctx.fillText(bottomLine2, bottomLine2Left, bottomLine2Top)
    this.ctx.fillText(gender, genderLeft, genderTop)
    this.ctx.fillText(issueDate, issueDateLeft, issueDateTop)

    // AND HERE ARE THE PARAMETERS FOR THE PORTRAIT IMAGE
    // I'D RECOMMEND TWEAKING ONLY THE RENDER BOX SETTINGS
    let renderBoxLeft = width * 0.0225
    let renderBoxTop = width * 0.115
    let renderBoxWidth = width * 0.33
    let renderBoxHeight = width * 0.405
    let renderBoxAspect = renderBoxWidth / renderBoxHeight

    // from here below we're calculating the portrait dimensions
    // and position based on its natural size & the size
    // dimensions of the render box. I'd recommend to not change
    // anything below this comment if you want to change the
    // portrait size or position. Change the render box instead!
    let portraitAspect =
      this.options.portraitImage.naturalWidth /
      this.options.portraitImage.naturalHeight
    let portraitLeft
    let portraitTop
    let portraitWidth
    let portraitHeight
    if (portraitAspect < renderBoxAspect) {
      // portrait is narrower
      portraitHeight = renderBoxHeight
      portraitWidth = portraitHeight * portraitAspect
      portraitTop = renderBoxTop
      portraitLeft = renderBoxLeft + (renderBoxWidth - portraitWidth) / 2
    } else {
      // portrait is wider
      portraitWidth = renderBoxWidth
      portraitHeight = portraitWidth / portraitAspect
      portraitLeft = renderBoxLeft
      portraitTop = renderBoxTop + (renderBoxHeight - portraitHeight) / 2
    }

    this.ctx.globalAlpha = 0.8

    this.ctx.drawImage(
      this.options.portraitImage,
      portraitLeft,
      portraitTop,
      portraitWidth,
      portraitHeight
    )

    this.ctx.globalAlpha = 1

    this.createCanvasTexture()
  }

  createCanvasTexture() {
    this.map = new THREE.Texture(this.canvas)
    this.map.needsUpdate = true
    this.map.flipX = true
    this.map.flipY = true
  }

  passportImageLoadHandler() {
    this.passportImage.onload = void 0
    this.isPassportImageLoaded = true
    this.drawPassport()
    this.options.onInitialRender()
  }

  set(newOptions) {
    objectAssign(this.options, newOptions)
    if (newOptions.width) {
      this.setCanvasDimensions(newOptions.width * DPR)
    }
    if (this.isPassportImageLoaded) {
      this.drawPassport()
    }
  }

  static generateBottomLines(surname, givenNames, birthDate, number, _iD) {
    // let d = new Date()
    let issueDate = _iD.year + _iD.month + _iD.day
    // d.getFullYear().toString() +
    // leftPad(d.getMonth() + 1, 2, '0') +
    // leftPad(d.getDate(), 2, '0')
    let checksum1 = Passport2dRenderer.generateChecksum1(
      surname,
      givenNames,
      birthDate,
      number,
      issueDate
    )
    let line1 = `E<U<<${number}`
    line1 = rightPadAngles(line1).slice(0, 20)
    line1 += issueDate
    line1 = rightPadAngles(line1).slice(0, 34)
    line1 += '>WWW.BILDERBUCHEUROPA.LOVE'
    line1 = rightPadAngles(line1)
    let line2 =
      surname.toUpperCase().replace(/\s/g, '<') +
      '<<' +
      givenNames.toUpperCase().replace(/\s/g, '<')
    line2 = rightPadAngles(line2).slice(0, 35)
    line2 += birthDate
    line2 = rightPadAngles(line2).slice(0, 48)
    line2 += '#europa22<<<<<'
    line2 += checksum1
    return [line1, line2]
  }

  static generateChecksum1(...words) {
    return leftPad(
      Math.abs(hashCode(words.join('')))
        .toString()
        .slice(0, 2),
      2,
      '0'
    )
  }
}

export default Passport2dRenderer
