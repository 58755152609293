import React from 'react'
import PropTypes from 'prop-types'

import GraphemeSplitter from 'grapheme-splitter'

import { getSaved, hashCode } from 'lib/helpers'
import { number } from 'lib/unicode-regexes'
import badWords from 'lib/bad-words'
import { getOrientation, transformToResetOrientation } from 'lib/exif'

import defaultPortrait from 'images/default-portrait.png'
import './index.scss'

const PORTRAIT_MAX_WIDTH = 1000
const PORTRAIT_MAX_HEIGHT = 1000
const MAX_SURNAME_LENGTH = 15
const MAX_GIVEN_NAMES_LENGTH = 15
const MAX_BIRTH_DATE_LENGTH = 4

class Form extends React.Component {
  constructor(props) {
    super(props)

    this.splitter = new GraphemeSplitter()

    this.portraitChangeHandler = this.portraitChangeHandler.bind(this)
    this.surnameChangeHandler = this.surnameChangeHandler.bind(this)
    this.givenNamesChangeHandler = this.givenNamesChangeHandler.bind(this)
    this.birthDateChangeHandler = this.birthDateChangeHandler.bind(this)
    this.genderChangeHandler = this.genderChangeHandler.bind(this)

    this.portraitImageCanvas = null
    this.portraitFileField = React.createRef()
    this.maxPortraitWidth = PORTRAIT_MAX_WIDTH
    this.maxPortraitHeight = PORTRAIT_MAX_HEIGHT

    this.isDefaultPortrait = true
    this.state = {
      portrait: defaultPortrait,
      surname: getSaved('form-surname', ''),
      givenNames: getSaved('form-givenNames', ''),
      birthDate: getSaved('form-birthDate', ''),
    }
  }

  componentDidMount() {
    if (
      !this.isDefaultPortrait ||
      this.state.surname !== '' ||
      this.state.givenNames !== '' ||
      this.state.birthDate !== ''
    ) {
      this.props.onDataChange({
        surname: this.state.surname,
        givenNames: this.state.givenNames,
        birthDate: this.state.birthDate,
      })
    }
    this.portraitImageCanvas = document.createElement('canvas')
    this.renderPortraitURL(defaultPortrait)
  }

  setMaxPortraitDimensions(passportRenderWidth) {
    let width = passportRenderWidth * 0.33
    let height = passportRenderWidth * 0.405
    this.maxPortraitWidth = Math.min(width, PORTRAIT_MAX_WIDTH)
    this.maxPortraitHeight = Math.min(height, PORTRAIT_MAX_HEIGHT)
  }

  renderPortraitURL(url, orientation = 0) {
    let image = new Image()
    image.onload = () => {
      let width = image.naturalWidth
      let height = image.naturalHeight
      let aspect = width / height
      if (width > this.maxPortraitWidth) {
        width = this.maxPortraitWidth
        height = width / aspect
      }
      if (height > this.maxPortraitHeight) {
        height = this.maxPortraitHeight
        width = height * aspect
      }
      if (
        orientation === 6 ||
        orientation === 8 ||
        orientation === 5 ||
        orientation === 7
      ) {
        this.portraitImageCanvas.width = height
        this.portraitImageCanvas.height = width
      } else {
        this.portraitImageCanvas.width = width
        this.portraitImageCanvas.height = height
      }

      if (!this.ctx) {
        this.ctx = this.portraitImageCanvas.getContext('2d')
      }

      transformToResetOrientation(this.ctx, orientation, width, height)

      this.ctx.drawImage(image, 0, 0, width, height)

      if (!this.isDefaultPortrait) {
        let portrait = this.portraitImageCanvas.toDataURL('image/jpeg')
        this.props.onDataChange({ portrait, portraitHash: hashCode(portrait) })
        this.setState({ portrait })
      }
    }
    image.src = url
  }

  portraitChangeHandler() {
    let { files } = this.portraitFileField.current
    if (files.length) {
      let [file] = files
      let reader = new FileReader()
      reader.onload = () => {
        getOrientation(file, orientation => {
          this.isDefaultPortrait = false
          this.renderPortraitURL(reader.result, orientation)
        })
      }
      reader.readAsDataURL(file)
    }
  }

  surnameChangeHandler({ target }) {
    // limit surname length
    let surnameChars = this.splitter.splitGraphemes(target.value)
    if (surnameChars.length > MAX_SURNAME_LENGTH) {
      surnameChars = surnameChars.slice(0, MAX_SURNAME_LENGTH)
    }
    let surname = surnameChars.join('')
    if (badWords.test(surname)) {
      surname = '💩'
    }
    // localStorage.setItem('form-surname', surname)
    this.props.onDataChange({ surname })
    this.setState({ surname })
  }

  givenNamesChangeHandler({ target }) {
    // limit givenNames length
    let givenNamesChars = this.splitter.splitGraphemes(target.value)
    if (givenNamesChars.length > MAX_GIVEN_NAMES_LENGTH) {
      givenNamesChars = givenNamesChars.slice(0, MAX_GIVEN_NAMES_LENGTH)
    }
    let givenNames = givenNamesChars.join('')
    if (badWords.test(givenNames)) {
      givenNames = '💩'
    }
    // localStorage.setItem('form-givenNames', givenNames)
    this.props.onDataChange({ givenNames })
    this.setState({ givenNames })
  }

  birthDateChangeHandler({ target }) {
    // limit birthDate length
    let birthDateChars = this.splitter.splitGraphemes(target.value)
    if (birthDateChars.length > MAX_BIRTH_DATE_LENGTH) {
      birthDateChars = birthDateChars.slice(0, MAX_BIRTH_DATE_LENGTH)
    }
    // throw out everything that isn't a digit, for a very broad
    // definition of digit
    let i = 0
    while (i < birthDateChars.length) {
      if (!number.test(birthDateChars[i])) {
        birthDateChars.splice(i, 1)
      } else {
        i++
      }
    }
    let birthDate = birthDateChars.join('')
    // localStorage.setItem('form-birthDate', birthDate)
    this.props.onDataChange({ birthDate })
    this.setState({ birthDate })
  }

  genderChangeHandler({ target }) {
    let gender = target.value
    // localStorage.setItem('form-gender', gender)
    this.props.onDataChange({ gender })
    this.setState({ gender })
  }

  render() {
    return (
      <form className="form">
        <div className="form__row form__row--image">
          <label className="label label--image" htmlFor="portrait-field">
            <p className="form__image__text">
              Please upload a current photo. Position yourself in front of a
              neutral background. Keep your mobile phone straight in front of
              your face with an arm&apos;s length distance and look into the
              camera without emotion. Perfect.
            </p>
            <p>Picture</p>
          </label>
          <div className="form__image-wrapper">
            <div className="form__image__button form__button">
              <label htmlFor="portrait-field" className="button button--image">
                Select file
              </label>
            </div>
            <div className="form__image">
              <div className="form__image__img-wrapper">
                <img className="form__image__img" src={this.state.portrait} />
              </div>
            </div>
            <input
              className="input--file"
              ref={this.portraitFileField}
              type="file"
              accept="image/*{/* let mobile users take photo right from the website */}"
              id="portrait-field"
              onChange={this.portraitChangeHandler}
            />
          </div>
        </div>
        <div className="form__row">
          <label className="label" htmlFor="surname-field">
            Surname
          </label>
          <div className="input-wrapper">
            <input
              type="text"
              className="input--text form__button"
              id="surname-field"
              value={this.state.surname}
              placeholder="-"
              onChange={this.surnameChangeHandler}
            />
          </div>
        </div>
        <div className="form__row">
          <label className="label" htmlFor="given-names-field">
            Given Names
          </label>
          <div className="input-wrapper">
            <input
              type="text"
              className="input--text form__button"
              id="given-names-field"
              value={this.state.givenNames}
              placeholder="-"
              onChange={this.givenNamesChangeHandler}
            />
          </div>
        </div>
        <div className="form__row">
          <label className="label" htmlFor="birth-date-field">
            Born in
          </label>
          <div className="input-wrapper">
            <input
              type="text"
              className="input--date form__button"
              id="birth-date-field"
              min={1800}
              max={2019}
              value={this.state.birthDate}
              placeholder={'YYYY'}
              onChange={this.birthDateChangeHandler}
            />
          </div>
        </div>
        <div className="form__row form__row--gender">
          <div className="label">Gender</div>
          <div className="input-wrapper input-wrapper--gender">
            {['female', 'male', 'unisex'].map(gender => (
              <div key={gender} className="input--gender">
                <input
                  type="radio"
                  className="radio-button"
                  id={`gender-field-${gender}`}
                  value={gender}
                  onChange={this.genderChangeHandler}
                  name="gender"
                />
                <label
                  className="fake-radio-button"
                  htmlFor={`gender-field-${gender}`}
                >
                  <div className="fake-radio-button__inner-circle" />
                  <span className="fake-radio-button__text">{gender}</span>
                </label>
                <label
                  className="radio-button-label"
                  htmlFor={`gender-field-${gender}`}
                >
                  {gender}
                </label>
              </div>
            ))}
          </div>
        </div>
      </form>
    )
  }
}

Form.propTypes = {
  onDataChange: PropTypes.func,
}

Form.defaultProps = {
  onDataChange() {},
}

export default Form
