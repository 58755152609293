import React from 'react'
import PropTypes from 'prop-types'
import './index.scss'

class SaveButton extends React.Component {
  constructor(props) {
    super(props)
    this.a = React.createRef()
  }

  setHref(uri) {
    if (this.a) {
      this.a.current.href = uri
    }
  }

  render() {
    return (
      <a
        className="save-button"
        ref={this.a}
        href=""
        download="passport.jpg"
        target="_blank"
        onClick={this.props.onClick}
      >
        <span>Download</span>
      </a>
    )
  }
}

SaveButton.propTypes = {
  onClick: PropTypes.func,
  isLocked: PropTypes.bool,
}

SaveButton.defaultProps = {
  onClick() {},
  isLocked: true,
}

export default SaveButton
