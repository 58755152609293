import React from 'react'
// import PropTypes from 'prop-types'
import './index.scss'

function Header() {
  return (
    <header className="header">
      <h1 className="headline">
        Bilderbuch<br />EUROPA<span className="headline__space"> </span>22
      </h1>
      <h2 className="headline--2">
        <span className="headline--2__hash">#</span>
        europa
        <span className="headline--2__number">22</span>
      </h2>
      <p className="header__copy">
        Ein Leben ohne Grenzen. <br />
        eine freedom zu verschenken. <br />
        Eine Freiheit, nicht zu denken.
      </p>
    </header>
  )
}

export default Header
