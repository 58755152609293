import React from 'react'
import PropTypes from 'prop-types'

import { getSaved, leftPad } from 'lib/helpers'
import ajax from 'lib/ajax'
import './index.scss'

const USE_AJAX =
  typeof window !== 'undefined' &&
  (window.location.host === 'bilderbuch.staging.unfun.de' ||
    window.location.host === 'bilderbucheuropa.love')
const AJAX_URL =
  typeof window !== 'undefined' &&
  window.location.protocol + '//' + window.location.host + '/count.php'

function pad(ppn) {
  return leftPad(ppn, 9, '0')
}

function getNextPassportNumber(callback) {
  if (USE_AJAX) {
    ajax(AJAX_URL, { post: true }, (err, res) => {
      callback(res.trim())
    })
  } else {
    setTimeout(() => {
      let nextCount = (
        parseInt(getSaved('counter-count', '0').trim(), 10) + 1
      ).toString()
      localStorage.setItem('counter-count', nextCount)
      callback(pad(nextCount))
    }, 0)
  }
}

class Counter extends React.Component {
  constructor(props) {
    super(props)
    this.state = { passportNumber: 'XXXXXXXX' }
  }

  componentDidMount() {
    getNextPassportNumber(passportNumber => {
      this.setState({ passportNumber })
      this.props.onLoad(passportNumber)
    })
  }

  tick(callback) {
    getNextPassportNumber(passportNumber => {
      this.setState({ passportNumber })
      callback(passportNumber)
    })
  }

  render() {
    return (
      <div className="counter">
        <p className="counter__text">EU Passport</p>
        <p className="counter__number">NR.: {this.state.passportNumber}</p>
      </div>
    )
  }
}

Counter.propTypes = {
  onLoad: PropTypes.func,
}

Counter.defaultProps = {
  onLoad() {},
}

export default Counter
