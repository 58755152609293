import * as THREE from 'three'

import { smallestPowerOfTwoOver } from './helpers'

const MAX_EDGE_LENGTH = 4096

export default class PassportHoloPortraitMapsRenderer {
  constructor(minEdgeLength, portrait) {
    this.canvas = document.createElement('canvas')
    this.context = this.canvas.getContext('2d')
    this.setEdgeLength(minEdgeLength)
    this.setPortrait(portrait)
    this.alphaMap = null
    this.specularMap = null
  }

  setEdgeLength(minEdgeLength) {
    this.edgeLength = smallestPowerOfTwoOver(minEdgeLength)
    if (this.edgeLength > MAX_EDGE_LENGTH) {
      this.edgeLength = MAX_EDGE_LENGTH
    }
    this.renderBoxLeft = this.edgeLength * 0.63
    this.renderBoxTop = this.edgeLength * 0.115
    this.renderBoxWidth = this.edgeLength * 0.33
    this.renderBoxHeight = this.edgeLength * 0.405
    this.renderBoxAspect = this.renderBoxWidth / this.renderBoxHeight
    this.canvas.width = this.edgeLength
    this.canvas.height = this.edgeLength
  }

  setPortrait(portrait) {
    this.portrait = portrait
    this.drawMaps()
  }

  drawMaps() {
    this.calculateDimensions()
    this.drawAlphaMap()
    this.drawSpecularMap()
  }

  drawAlphaMap() {
    this.fillBlack()
    this.drawPortrait()
    this.alphaMap = this.createCanvasTexture()
  }

  drawSpecularMap() {
    // reuse black background & portrait from alphaMap
    // invert portrait colors
    this.context.globalCompositeOperation = 'difference'
    this.context.fillStyle = 'white'
    this.context.fillRect(
      this.portraitLeft,
      this.portraitTop,
      this.portraitWidth,
      this.portraitHeight
    )
    this.context.globalCompositeOperation = 'source-over'
    this.specularMap = this.createCanvasTexture()
  }

  calculateDimensions() {
    let portraitAspect =
      this.portrait.naturalWidth / this.portrait.naturalHeight
    if (portraitAspect < this.renderBoxAspect) {
      // portrait is narrower
      this.portraitWidth = this.renderBoxHeight * portraitAspect
      this.portraitHeight = this.renderBoxHeight
      this.portraitLeft =
        this.renderBoxLeft + (this.renderBoxWidth - this.portraitWidth) / 2
      this.portraitTop = this.renderBoxTop
    } else {
      // portrait is wider
      this.portraitWidth = this.renderBoxWidth
      this.portraitHeight = this.renderBoxWidth / portraitAspect
      this.portraitLeft = this.renderBoxLeft
      this.portraitTop =
        this.renderBoxTop + (this.renderBoxHeight - this.portraitHeight) / 2
    }
  }

  fillBlack() {
    this.context.fillStyle = 'black'
    this.context.fillRect(0, 0, this.edgeLength, this.edgeLength)
  }

  drawPortrait() {
    this.context.drawImage(
      this.portrait,
      this.portraitLeft,
      this.portraitTop,
      this.portraitWidth,
      this.portraitHeight
    )
  }

  createCanvasTexture() {
    let canvasTexture = new THREE.Texture(this.canvas)
    canvasTexture.needsUpdate = true
    canvasTexture.flipX = true
    canvasTexture.flipY = true
    return canvasTexture
  }
}
