import React from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash.debounce'

import Passport2dRenderer from 'lib/passport-2d-renderer'
import PassportHoloPortraitMapsRenderer from '../../lib/passport-holo-portrait-maps-renderer'
import Passport3dScene from './passport-3d-scene'

import defaultPortrait from 'images/default-portrait.png'
import PassportPerspective from './passport-perspective'

import './index.scss'

class Passport extends React.Component {
  constructor(props) {
    super(props)
    this.initialRenderHandler = this.initialRenderHandler.bind(this)
    this.portraitImageLoadHandler = this.portraitImageLoadHandler.bind(this)
    this.debouncedResizeHandler = debounce(this.resizeHandler.bind(this), 200)
    this.isUnmounted = false
    this.passport3dScene = React.createRef()
    this.passport2dRenderer = null
    this.holoPortraitMapsRenderer = null
    this.state = {
      textureMap: null,
      holoPortraitAlphaMap: null,
      holoPortraitSpecularMap: null,
    }
    this.portraitImage = null
  }

  componentDidMount() {
    this.portraitImage = new Image()
    this.passport3dScene.current.setResolution()
    this.passport2dRenderer = new Passport2dRenderer({
      width: this.passport3dScene.current.renderWidth,
      portraitImage: this.portraitImage,
      onInitialRender: this.initialRenderHandler,
      surname: this.props.surname,
      givenNames: this.props.givenNames,
      birthDate: this.props.birthDate,
      number: this.props.number,
      gender: this.props.gender,
    })
    this.holoPortraitMapsRenderer = new PassportHoloPortraitMapsRenderer(
      this.passport3dScene.current.noLimitRenderWidth,
      this.portraitImage
    )
    window.addEventListener('resize', this.debouncedResizeHandler)
    this.portraitImage.onload = this.portraitImageLoadHandler
    this.portraitImage.src = this.props.portrait
  }

  componentWillUnmount() {
    this.isUnmounted = true
    window.removeEventListener('resize', this.debouncedResizeHandler)
    this.portraitImage.onload = void 0
  }

  componentDidUpdate(prevProps) {
    if (this.props.portraitHash !== prevProps.portraitHash) {
      this.portraitImage.src = this.props.portrait
    }
    if (this.props.surname !== prevProps.surname) {
      this.passport2dRenderer.set({ surname: this.props.surname })
    }
    if (this.props.givenNames !== prevProps.givenNames) {
      this.passport2dRenderer.set({ givenNames: this.props.givenNames })
    }
    if (this.props.birthDate !== prevProps.birthDate) {
      this.passport2dRenderer.set({ birthDate: this.props.birthDate })
    }
    if (this.props.number !== prevProps.number) {
      this.passport2dRenderer.set({ number: this.props.number })
    }
    if (this.props.gender !== prevProps.gender) {
      this.passport2dRenderer.set({ gender: this.props.gender })
    }
    if (
      this.props.surname !== prevProps.surname ||
      this.props.givenNames !== prevProps.givenNames ||
      this.props.birthDate !== prevProps.birthDate ||
      this.props.number !== prevProps.number ||
      this.props.gender !== prevProps.gender
    ) {
      if (this.passport2dRenderer.isPassportImageLoaded) {
        this.updateStateCanvases()
      }
    }
  }

  updateStateCanvases() {
    this.setState({
      textureMap: this.passport2dRenderer.map,
      holoPortraitAlphaMap: this.holoPortraitMapsRenderer.alphaMap,
      holoPortraitSpecularMap: this.holoPortraitMapsRenderer.specularMap,
    })
  }

  portraitImageLoadHandler() {
    if (this.isUnmounted) {
      return
    }
    this.passport2dRenderer.set({ portraitImage: this.portraitImage })
    this.holoPortraitMapsRenderer.setPortrait(this.portraitImage)
    if (this.passport2dRenderer.isPassportImageLoaded) {
      this.updateStateCanvases()
    }
  }

  initialRenderHandler() {
    this.updateStateCanvases()
  }

  getImage() {
    this.passport3dScene.current.renderForDownload()
    return this.passport3dScene.current.getImage()
  }

  downloadImage() {
    this.passport3dScene.current.downloadImage()
  }

  resizeHandler() {
    if (this.isUnmounted) {
      return
    }
    this.passport3dScene.current.setResolution()
    this.passport2dRenderer.set({
      width: this.passport3dScene.current.renderWidth,
    })
    this.holoPortraitMapsRenderer.setEdgeLength(
      this.passport3dScene.current.noLimitRenderWidth
    )
    this.props.onResize(this.passport3dScene.current.renderWidth)
  }

  render() {
    return (
      <div className="passport-wrapper">
        <PassportPerspective className="passport-perspektive" intensity={700}>
          <Passport3dScene
            ref={this.passport3dScene}
            textureMap={this.state.textureMap}
            holoPortraitAlphaMap={this.state.holoPortraitAlphaMap}
            holoPortraitSpecularMap={this.state.holoPortraitSpecularMap}
          />
        </PassportPerspective>
      </div>
    )
  }
}

Passport.propTypes = {
  portrait: PropTypes.string,
  portraitHash: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  surname: PropTypes.string,
  givenNames: PropTypes.string,
  birthDate: PropTypes.string,
  number: PropTypes.string,
  gender: PropTypes.string,
  onResize: PropTypes.func,
}

Passport.defaultProps = {
  portrait: defaultPortrait,
  portraitHash: void 0,
  surname: '',
  givenNames: '',
  birthDate: '',
  number: 'XXXXXXXX',
  gender: '',
  onResize: () => {},
}

export default Passport
