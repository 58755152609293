/**
 * detect IE
 * returns version of IE or false, if browser is not Internet Explorer
 */
function webglDetect() {
  var canvas = typeof document !== 'undefined' && document.createElement('canvas')
  var contextNames = ['webgl', 'experimental-webgl', 'moz-webgl', 'webkit-3d']
  var context

  if (
    typeof navigator !== 'undefined' &&
    ~navigator.userAgent.indexOf('MSIE')
  ) {
    try {
      /*global WebGLHelper*/
      context = WebGLHelper.CreateGLContext(canvas, 'canvas')
    } catch (e) {
      console.log(e)
    }
  } else {
    for (var i = 0; i < contextNames.length; i++) {
      try {
        context = canvas && canvas.getContext(contextNames[i])
        if (context) {
          break
        }
      } catch (e) {
        console.log(e)
      }
    }
  }
  return !!context
}

export default function useFallback() {
  var ua = typeof window !== 'undefined' ? window.navigator.userAgent : ''

  if (!webglDetect()) return true

  var msie = ua.indexOf('MSIE ')
  if (msie > 0) {
    // IE 10 or older => return version number
    return true
  }

  var trident = ua.indexOf('Trident/')
  if (trident > 0) {
    // IE 11 => return version number
    return true
  }

  // other browser
  return false
}
