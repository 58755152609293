import portraitPhilipp from '../images/philipp_.jpg'
import portraitMichael from '../images/michael_.jpg'
import portraitMaurice from '../images/maurice_.jpg'
import portraitPeter from '../images/peter_.jpg'

export const BAND_MEMBER_IDS = [
  {
    surname: '€rnst',
    givenNames: 'Maurice',
    birthDate: '1988',
    gender: 'unisex',
    portrait: portraitMaurice,
    portraitHash: 'maurice',
  },
  {
    surname: 'Mike',
    givenNames: 'Snacky',
    birthDate: '1991',
    gender: 'unisex',
    portrait: portraitMichael,
    portraitHash: 'michael',
  },
  {
    surname: 'Horazdovsky',
    givenNames: 'Peter',
    birthDate: '1990',
    gender: 'unisex',
    portrait: portraitPeter,
    portraitHash: 'peter',
  },
  {
    surname: 'Earth',
    givenNames: 'One',
    birthDate: '1992',
    gender: 'unisex',
    portrait: portraitPhilipp,
    portraitHash: 'philipp',
  },
]

export function getBandMemberID(n) {
  return BAND_MEMBER_IDS[n]
}

export function getRandomBandMemberID() {
  return getBandMemberID(Math.floor(Math.random() * BAND_MEMBER_IDS.length))
}
