import React, { useEffect } from 'react'
import { useSpring, animated } from 'react-spring'

export default function PassportPerspective({ children, intensity = 100, className }) {
    //   const [window, setWindow] = useState(null)
    const [pro, set] = useSpring(() => ({
        xys: [0, 0, 1],
        config: { mass: 5, tension: 350, friction: 40 },
    }))

    const calc = (x, y) => window ? [-(y - window.innerHeight / 2) / intensity, (x - window.innerWidth / 2) / intensity, 1] : [0, 0, 1]
    const trans = (x, y, s) => `perspective(1000px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`

    useEffect(() => {
        document.body.addEventListener('mousemove', onMouseMove)
        return () => {
            document.body.removeEventListener('mousemove', onMouseMove)
        }
    })

    function onMouseMove({ clientX: x, clientY: y }) {
        set({ xys: calc(x, y) })
    }

    return (
        <animated.div
            className={className}
            style={{ transform: pro.xys.interpolate(trans) }}
        >
            {children}
        </animated.div>
    )
}
