import React from 'react'
import './index.scss'
import playIcon from '../../images/play-icon.svg'

const Footer = ({ children }) => (
  <footer className="footer">
    <div className="footer__download">{children}</div>

    <div className="footer__streaming-links">
      <a
        className="footer__streaming-link"
        href="http://umg.lnk.to/europa22"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img className="footer__streaming-play-icon" src={playIcon} />
        <span className="footer__streaming-link-text">
          Listen to{' '}
          <span className="footer__streaming-link-text-black">Europa22.</span>
        </span>
      </a>{' '}
    </div>

    <nav className="footer__nav">
      <a className="footer__link footer__navlink" href="/privacy">
        Privacy
      </a>
      <a className="footer__link footer__navlink" href="/impressum">
        Imprint
      </a>
    </nav>
    <div className="footer__site-credits">
      Concept and Design:{' '}
      <a
        className="footer__link"
        href="http://instagram.com/simone_cihlar"
        target="_blank"
        rel="noopener noreferrer"
      >
        Simone&nbsp;Cihlar
      </a>
      ,{' '}
      <a
        className="footer__link"
        href="http://claudiarafael.de/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Claudia&nbsp;Rafael
      </a>
      ,{' '}
      <a className="footer__link" href="https://unfun.de" target="_blank">
        unfun.de
      </a>{' '}
      and{' '}
      <a
        className="footer__link"
        href="http://bilderbuch-musik.at/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Maurice&nbsp;Ernst
      </a>
      <br />
      UX/Programmierung:{' '}
      <a
        className="footer__link"
        href="https://unfun.de"
        target="_blank"
        rel="noopener noreferrer"
      >
        unfun.de
      </a>
    </div>
  </footer>
)

export default Footer
