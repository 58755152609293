export default function ajax(url, options = {}, callback) {
  let method = options.post ? 'POST' : 'GET'
  let data = options.data
  let json = options.json

  if (typeof options === 'function') {
    callback = options
  }

  let request = new XMLHttpRequest()
  request.open(method, url + getUrlQuery(method, data, json), true)
  request.setRequestHeader('Content-Type', getContentTypeHeader(json))
  request.onload = makeLoadHandler(request, callback, json)
  request.onerror = error => callback(error)
  request.send(getPayload(method, data, json))
}

function serialize(data, json) {
  if (json) {
    return JSON.stringify(data)
  }
  return serializeQueryString(data)
}

function deserialize(response, json) {
  if (json) {
    return JSON.parse(response)
  }
  return response
}

function serializeQueryString(data, prefix) {
  let query = []
  let prop
  for (prop in data) {
    if (data.hasOwnProperty(prop)) {
      let key = prefix ? prefix + '[' + prop + ']' : prop
      let value = data[prop]
      query.push(
        typeof value === 'object'
          ? serializeQueryString(value, key)
          : encodeURIComponent(key) + encodeURIComponent(value)
      )
    }
  }
  return query.join('&')
}

function makeLoadHandler(request, callback, json) {
  return () => {
    if (request.status >= 200 && request.status < 400) {
      callback(null, deserialize(request.responseText, json))
    } else {
      callback(request)
    }
  }
}

function getContentTypeHeader(json) {
  if (json) {
    return 'application/json'
  } else {
    return 'application/x-www-form-urlencoded; charset=UTF-8'
  }
}

function getUrlQuery(method, data, json) {
  if (method === 'GET' && data) {
    return '?' + serialize(data, json)
  }
  return ''
}

function getPayload(method, data, json) {
  if (method === 'POST') {
    return serialize(data, json)
  }
  return void 0
}
