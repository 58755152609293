export function getSaved(key, defaultValue = null) {
  // if (typeof localStorage !== 'undefined') {
  //   let savedValue = localStorage.getItem(key)
  //   if (savedValue !== null) {
  //     return savedValue
  //   }
  // }
  return defaultValue
}

export function leftPad(string, length, padChar = ' ') {
  let _s = string
  while (_s.length < length) _s = padChar + _s
  return _s
}

export function objectAssign(object, ...objects) {
  let key
  objects.forEach(o => {
    for (key in o) {
      if (o.hasOwnProperty(key)) {
        object[key] = o[key]
      }
    }
  })
  return object
}

export function shallowObjectEqual(object1, object2) {
  let key
  for (key in object1) {
    if (object1.hasOwnProperty(key)) {
      if (object2[key] !== object1[key]) {
        return false
      }
    }
  }
  for (key in object2) {
    if (object2.hasOwnProperty(key)) {
      if (object1[key] !== object2[key]) {
        return false
      }
    }
  }
  return true
}

export function smallestPowerOfTwoOver(n) {
  let p = 2
  while (p < n) {
    p *= 2
  }
  return p
}

// https://stackoverflow.com/a/7616484
// https://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
export function hashCode(string) {
  let hash = 0
  let i
  let chr
  if (string.length === 0) {
    return hash
  }
  for (i = 0; i < string.length; i++) {
    chr = string.charCodeAt(i)
    hash = (hash << 5) - hash + chr
    hash |= 0 // Convert to 32bit integer
  }
  return hash
}

export function mapRange(input, inMin, inMax, outMin, outMax) {
  let output = ((input - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin

  if (outMin < outMax) {
    if (output < outMin) {
      output = outMin
    }
    if (output > outMax) {
      output = outMax
    }
  }

  if (outMin > outMax) {
    if (output > outMin) {
      output = outMin
    }
    if (output < outMax) {
      output = outMax
    }
  }

  return output
}
