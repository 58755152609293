import React from 'react'
import SEO from 'components/seo'
import Layout from 'components/layout'
import Counter from 'components/counter'
import Passport from 'components/passport'
import SaveButton from 'components/save-button'
import Form from 'components/form'
import Loader from 'components/loader'
import AfterSaveModal from 'components/modal'
import Header from 'components/header'
import Footer from 'components/footer'
import Fallback from 'components/fallback'
import debounce from 'lodash.debounce'
import useFallback from 'lib/useFallback'
import detectMobileBrowser from 'lib/detectMobileBrowser'

import { getRandomBandMemberID } from '../lib/band-member-ids'

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.counter = React.createRef()
    this.passport = React.createRef()
    this.saveButton = React.createRef()
    this.form = React.createRef()
    this.getPassportImage = this.getPassportImage.bind(this)
    this.saveButtonClickHandler = this.saveButtonClickHandler.bind(this)
    this.formDataChangeHandler = this.formDataChangeHandler.bind(this)
    this.passportNumberLoadHandler = this.passportNumberLoadHandler.bind(this)
    this.afterSaveConfirmHandler = this.afterSaveConfirmHandler.bind(this)
    this.passportResizeHandler = this.passportResizeHandler.bind(this)
    this.state = {
      useFallback: false,
      surname: '',
      givenNames: '',
      birthDate: '',
      portrait: void 0,
      portraitHash: 0,
      passportNumber: 'XXXXXXXX',
      isSaveButtonLocked: true,
      bandMemberID: getRandomBandMemberID(),
      isLoaderActive: true,
      isFormPristine: true,
      isAfterSaveModalActive: false,
    }
  }

  componentDidMount() {
    const fallback = useFallback()
    if (fallback) this.setState({ useFallback: true })

    let debounceInterval = 250
    if (detectMobileBrowser()) {
      debounceInterval = 600
    }
    this.formDataChangeHandler = debounce(
      this.formDataChangeHandler,
      debounceInterval
    )

    this.toggleLoader()

    window.setTimeout(() => {
      console.clear()
      console.log(
        '========================================================================='
      )
      console.log(
        'Special thanks to http://kimasendorf.com/ for phong.technology ®'
      )
      console.log(
        '========================================================================='
      )

      console.log(
        'Code: https://unfun.de',
        'https://andreasfaust.de',
        'https://etannert.de'
      )

      console.log(
        '========================================================================='
      )
    }, 3000)
  }

  toggleLoader() {
    this.setState({ isLoaderActive: !this.state.isLoaderActive })
  }

  getPassportImage() {
    return this.passport.current.getImage()
  }

  saveButtonClickHandler(event) {
    if (this.passport && this.saveButton && this.counter) {
      if (window.navigator.userAgent.indexOf('Edge') > -1) {
        event.preventDefault()
        this.passport.current.downloadImage()
      } else {
        let image = this.passport.current.getImage()
        this.saveButton.current.setHref(image)
      }
      this.setState({ isSaveButtonLocked: true, isAfterSaveModalActive: true })
      this.counter.current.tick(this.passportNumberLoadHandler)
    } else {
      event.preventDefault()
    }
  }

  formDataChangeHandler(data) {
    let newState = data
    newState.isFormPristine = false
    this.setState(newState)
  }

  passportNumberLoadHandler(passportNumber) {
    this.setState({
      passportNumber,
      isSaveButtonLocked: false,
    })
  }

  afterSaveConfirmHandler() {
    this.setState({ isAfterSaveModalActive: false })
  }

  passportResizeHandler(passportRenderWidth) {
    this.form.current.setMaxPortraitDimensions(passportRenderWidth)
  }

  render() {
    // render the random band member into the lower passport if
    // (we're on desktop) AND (the form is untouched)
    let portrait = this.state.portrait
    let portraitHash = this.state.portraitHash
    let surname = this.state.surname
    let givenNames = this.state.givenNames
    let birthDate = this.state.birthDate
    let passportNumber = this.state.passportNumber
    let gender = this.state.gender

    if (this.state.isFormPristine) {
      portrait = this.state.bandMemberID.portrait
      portraitHash = this.state.bandMemberID.portraitHash
      surname = this.state.bandMemberID.surname
      givenNames = this.state.bandMemberID.givenNames
      birthDate = this.state.bandMemberID.birthDate
      gender = this.state.bandMemberID.gender
      passportNumber = '000000000'
    }

    if (this.state.useFallback) {
      return <Fallback />
    }

    return (
      <Layout>
        <SEO title="Home" keywords={[]} />
        <section className="wrapper--left">
          <Header />
          <Form ref={this.form} onDataChange={this.formDataChangeHandler} />
        </section>
        <section className="wrapper--right">
          <Passport
            ref={this.passport}
            portrait={portrait}
            portraitHash={portraitHash}
            surname={surname}
            givenNames={givenNames}
            birthDate={birthDate}
            number={passportNumber}
            gender={gender}
            onResize={this.passportResizeHandler}
          />
          <Footer>
            <SaveButton
              ref={this.saveButton}
              onClick={this.saveButtonClickHandler}
              isLocked={this.state.isSaveButtonLocked}
            />
            <Counter
              ref={this.counter}
              onLoad={this.passportNumberLoadHandler}
            />
          </Footer>
        </section>
        <AfterSaveModal
          onConfirm={this.afterSaveConfirmHandler}
          show={this.state.isAfterSaveModalActive}
          isSaveButtonLocked={this.isSaveButtonLocked}
        />
        <Loader show={this.state.isLoaderActive} />
      </Layout>
    )
  }
}

export default IndexPage
