import React from 'react'
// import PropTypes from 'prop-types'
import EuropeFlag from 'images/europe-flag-red.svg'
import Layout from 'components/layout'
import Footer from 'components/footer'
// import './index.scss'

function Fallback() {
  return (
    <Layout>
      <div className="fallback">
        <div className="modal__inner">
          <div className="modal__img-wrapper">
            <img src={EuropeFlag} alt="Europe Flag" className="modal__img" />
          </div>
          <div className="modal__text">
            <h1 className="modal__headline">
              This site does not work in your browser!
            </h1>
            <p className="modal__p">Please use a modern browser to create your European Identity.</p>
            <p className="modal__p">Baba Bussis – Yours Bilderbuchs</p>
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}


export default Fallback
