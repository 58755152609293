import React from 'react'
import PropTypes from 'prop-types'
import { mapRange } from 'lib/helpers'

import { saveAs } from 'file-saver'
import canvasToBlobPolyfill from '../../lib/canvasToBlobPolyfill'

import * as THREE from 'three'

import './index.scss'

import envmapPX from 'images/px.jpg'
import envmapNX from 'images/nx.jpg'
import envmapPY from 'images/py.jpg'
import envmapNY from 'images/ny.jpg'
import envmapPZ from 'images/pz.jpg'
import envmapNZ from 'images/nz.jpg'

import specular0 from 'images/specular0.jpg'
import specular0small from 'images/specular0-small.jpg'

import specular1 from 'images/specular1.jpg'
import specular1small from 'images/specular1-small.jpg'

import specular2 from 'images/specular2.jpg'
import specular2small from 'images/specular2-small.jpg'

import specular3 from 'images/specular3.jpg'
import specular3small from 'images/specular3-small.jpg'

import passportBlank from 'images/passport-blank.jpg'
import blackSquare from '../../images/black-square.jpg'

// Get the device pixel ratio, falling back to 1
const DPR = (typeof window !== 'undefined' && window.devicePixelRatio) || 1

const MIN_RENDER_HEIGHT = 1080

class Passport3dScene extends React.Component {
  constructor(props) {
    super(props)
    this.container = React.createRef()
    this.canvas = React.createRef()

    this.devicetiltHandler = this.devicetiltHandler.bind(this)
    this.mousemoveHandler = this.mousemoveHandler.bind(this)
    this._renderLoop = this._renderLoop.bind(this)

    this.camera = null
    this.scene = null
    this.renderer = null
    this.material = null
    this.mesh = null
    this.group = null

    this.frame = 0
    this.tiltX = 0
    this.tiltY = 0

    this.devicemovement = 0

    this.raf = null

    this.renderWidth = 0
    this.renderHeight = 0
    this.noLimitRenderWidth = 0
  }

  componentDidMount() {
    if (typeof document !== 'undefined') {
      canvasToBlobPolyfill()
    }

    let textureLoader = new THREE.TextureLoader()
    let cubeTextureLoader = new THREE.CubeTextureLoader()

    this.camera = new THREE.Camera()
    window.camera = this.camera // FIXME delete this line
    this.camera.position.z = 1
    this.scene = new THREE.Scene()
    this.renderer = new THREE.WebGLRenderer({
      preserveDrawingBuffer: true,
      canvas: this.canvas.current,
    })

    // passport aspect ratio:
    // width 100%, height: 63.106%
    // --> map this on a 2 * 2 square, you get a factor
    //     like 63.106 times WHAT equals 2?
    //     -> 2 / 63.106 = 0.031693
    // --> now scale the square so that 63.106 (the height of the
    //     passport) will be 2 (the height of the in-canvas
    //     viewport), so to know how high it has to be in units,
    //     we need to find out how many units would be 100%
    //     -> 100 times that factor, in other words:
    //     -> 100 * 0.031693 = 3.169271
    let geometry = new THREE.PlaneBufferGeometry(2, 3.169271)
    geometry.clearGroups()
    geometry.addGroup(0, Infinity, 0)
    geometry.addGroup(0, Infinity, 1)
    geometry.addGroup(0, Infinity, 2)
    geometry.addGroup(0, Infinity, 3)
    geometry.addGroup(0, Infinity, 4)

    let texEnv = cubeTextureLoader.load([
      envmapPX,
      envmapNX,
      envmapPY,
      envmapNY,
      envmapPZ,
      envmapNZ,
    ])

    let specularMap0
    if (window.innerWidth < 800) {
      specularMap0 = textureLoader.load(specular0small)
    } else {
      specularMap0 = textureLoader.load(specular0)
    }

    this.material = new THREE.MeshPhongMaterial({
      color: 0xdddddd,
      emissive: 0x000000,
      specular: 0xffffff,
      shininess: 10,
      map: textureLoader.load(passportBlank),
      bumpMap: specularMap0,
      bumpScale: 0.005,
      envMap: texEnv,
      specularMap: specularMap0,
      reflectivity: 0.6,
    })

    let specularMap1
    if (window.innerWidth < 800) {
      specularMap1 = textureLoader.load(specular1small)
    } else {
      specularMap1 = textureLoader.load(specular1)
    }

    this.holomaterial1 = new THREE.MeshPhongMaterial({
      color: 0xffffff,
      emissive: 0x000000,
      specular: 0xffffff,
      shininess: 80,
      alphaMap: specularMap1,
      bumpMap: specularMap1,
      bumpScale: 0.8,
      envMap: texEnv,
      specularMap: specularMap1,
      reflectivity: 0.9,
      transparent: true,
      opacity: 0,
    })

    let specularMap2
    if (window.innerWidth < 800) {
      specularMap2 = textureLoader.load(specular2small)
    } else {
      specularMap2 = textureLoader.load(specular2)
    }

    this.holomaterial2 = new THREE.MeshPhongMaterial({
      color: 0xffffff,
      emissive: 0x000000,
      specular: 0xffffff,
      shininess: 80,
      alphaMap: specularMap2,
      bumpMap: specularMap2,
      bumpScale: 0.8,
      envMap: texEnv,
      specularMap: specularMap2,
      reflectivity: 0.9,
      transparent: true,
      opacity: 0,
    })

    let specularMap3
    if (window.innerWidth < 800) {
      specularMap3 = textureLoader.load(specular3small)
    } else {
      specularMap3 = textureLoader.load(specular3)
    }

    this.holomaterial3 = new THREE.MeshPhongMaterial({
      color: 0xffffff,
      emissive: 0x000000,
      specular: 0xffffff,
      shininess: 80,
      alphaMap: specularMap3,
      bumpMap: specularMap3,
      bumpScale: 0.8,
      envMap: texEnv,
      specularMap: specularMap3,
      reflectivity: 0.9,
      transparent: true,
      opacity: 0,
    })

    this.holomaterial4 = new THREE.MeshPhongMaterial({
      color: 0xbbbbbb,
      emissive: 0x00ff00,
      alphaMap: textureLoader.load(blackSquare),
      bumpScale: 0.1,
      specular: 0xff0000,
      shininess: 0,
      envMap: texEnv,
      specularMap: textureLoader.load(blackSquare),
      reflectivity: 2,

      transparent: true,
      opacity: 0,
    })

    this.mesh = new THREE.Mesh(geometry, [
      this.material,
      this.holomaterial1,
      this.holomaterial2,
      this.holomaterial3,
      this.holomaterial4,
    ])
    // offset to the top should be half of how much the height of
    // the PlaneBufferGeometry above exceeds two, so:
    // --> 3.169271 - 2 = 1.169271
    // --> divided by 2: 1.169271 / 2 = 0.584635
    /* this.mesh.position.y = -0.584635 // calculated*/
    // but by trial & error another value seems better
    this.mesh.position.y = -0.5851 // trial & error
    window.mesh = this.mesh // FIXME delete this line
    // this.scene.add(this.mesh)

    this.group = new THREE.Object3D()
    this.group.add(this.mesh)
    this.group.add(this.camera)

    this.scene.add(this.group)

    this.scene.add(new THREE.AmbientLight(0xffffff))

    let lightObject = new THREE.Object3D()
    this.scene.add(lightObject)

    let pointLight = new THREE.PointLight(0xffffff, 0.2, 50)
    pointLight.position.set(0, 0, 10)
    lightObject.add(pointLight)

    let pointLight2 = new THREE.PointLight(0xffffff, 0.1, 30)
    pointLight2.position.set(0, 0, -10)
    lightObject.add(pointLight2)

    if ('ondeviceorientation' in window) {
      window.addEventListener('deviceorientation', this.devicetiltHandler)
    }
    if ('onmousemove' in window) {
      window.addEventListener('mousemove', this.mousemoveHandler)
    }
  }

  componentWillUnmount() {
    this.stopRenderLoop()

    if ('ondeviceorientation' in window) {
      window.removeEventListener('deviceorientation', this.devicetiltHandler)
    }
    if ('onmousemove' in window) {
      window.removeEventListener('mousemove', this.mousemoveHandler)
    }

    this.scene.dispose()
    this.renderer.dispose()
    this.material.dispose()
  }

  componentDidUpdate(prevProps) {
    let any = false
    if (
      this.props.textureMap &&
      this.props.textureMap !== prevProps.textureMap
    ) {
      this.material.map = this.props.textureMap
      any = true
    }
    if (
      this.props.holoPortraitAlphaMap &&
      this.props.holoPortraitAlphaMap !== prevProps.holoPortraitAlphaMap
    ) {
      this.holomaterial4.alphaMap = this.props.holoPortraitAlphaMap
      any = true
    }
    if (
      this.props.holoPortraitSpecularMap &&
      this.props.holoPortraitSpecularMap !== prevProps.holoPortraitSpecularMap
    ) {
      this.holomaterial4.specularMap = this.props.holoPortraitSpecularMap
      any = true
    }
    if (any) {
      this.startRenderLoop()
    }
  }

  getImage() {
    return this.canvas.current.toDataURL('image/jpeg')
  }

  downloadImage() {
    this.canvas.current.toBlob(blob => {
      saveAs(blob, 'passport.jpg')
    })
  }

  devicetiltHandler({ beta, gamma }) {
    let relBeta = (beta + 180) / 360
    let relGamma = (gamma + 90) / 180

    relBeta *= 1.5
    relGamma *= 1.5

    relBeta = relBeta % 1
    relGamma = relGamma % 1

    this.tilt(relGamma, relBeta)
  }

  mousemoveHandler({ clientX, clientY }) {
    let relX = clientX / document.body.offsetWidth
    let relY = clientY / window.innerHeight
    this.tilt(relX, relY)
  }

  tilt(x, y) {
    // receive values between in [0; 1],
    // map it to [0, 2PI]
    this.tiltX = x
    this.tiltY = y

    let ranges = [
      { material: this.holomaterial1, start: 0.0, end: 0.5 },
      { material: this.holomaterial2, start: 0.2, end: 0.7 },
      { material: this.holomaterial3, start: 0.5, end: 1 },
      { material: this.holomaterial4, start: 0.4, end: 0.7, maxOpacity: 0.7 },
    ]

    ranges.forEach(range => {
      let maxOpacity = range.maxOpacity || 1
      let opacity = Math.sin(mapRange(x, range.start, range.end, 0, Math.PI))
      range.material.opacity = opacity * maxOpacity
    })
  }

  update() {
    this.frame += 0.0025

    let shivering = Math.sin(this.frame) * 0.06

    this.group.rotation.y = (this.tiltX + shivering) * 2 * Math.PI
    this.group.rotation.x = ((-0.5 + this.tiltY) * Math.PI) / 2

    this.renderer.render(this.scene, this.camera)
  }

  setResolution() {
    let bcr = this.container.current.getBoundingClientRect()
    this.renderWidth = bcr.width * DPR
    this.renderHeight = bcr.height * DPR
    this.noLimitRenderWidth = Math.max(bcr.width, bcr.height)
    let aspect = bcr.width / bcr.height
    if (this.renderHeight < MIN_RENDER_HEIGHT) {
      this.renderHeight = MIN_RENDER_HEIGHT
      this.renderWidth = Math.ceil(this.renderHeight * aspect)
    }
    if (this.renderWidth < this.renderHeight) {
      ;[this.renderWidth, this.renderHeight] = [
        this.renderHeight,
        this.renderWidth,
      ]
    }
    this.renderer.setSize(this.renderWidth, this.renderHeight, false)
  }

  startRenderLoop() {
    if (this.raf === null) {
      this.setResolution()
      this.raf = requestAnimationFrame(this._renderLoop)
    }
  }

  stopRenderLoop() {
    cancelAnimationFrame(this.raf)
    this.raf = null
  }

  _renderLoop() {
    this.raf = requestAnimationFrame(this._renderLoop)
    this.update()
  }

  renderForDownload() {
    this.holomaterial1.opacity = 1
    this.holomaterial2.opacity = 1
    this.holomaterial3.opacity = 1
    this.holomaterial4.opacity = 0.3
    this.renderer.render(this.scene, this.camera)
  }

  render() {
    return (
      <div className="passport" ref={this.container}>
        <canvas ref={this.canvas} />
      </div>
    )
  }
}

Passport3dScene.propTypes = {
  textureMap: PropTypes.object,
  holoPortraitAlphaMap: PropTypes.object,
  holoPortraitSpecularMap: PropTypes.object,
}

export default Passport3dScene
