import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import EuropeFlag from 'images/europe-flag.svg'
import './index.scss'

function Loader({ show }) {
  const [br, setBr] = useState(false)

  function checkBr() {
    if (window.innerWidth >= 800) {
      setBr(false)
    } else {
      setBr(true)
    }
  }

  useEffect(() => {
    checkBr()
    window.addEventListener('resize', checkBr)
    return () => {
      window.removeEventListener('resize', checkBr)
    }
  }, [])
  return (
    <div className={'loader' + (show ? '' : ' loader--hidden')}>
      <p className="loader__text">
        Check your new Identity. {br && <br />} Be part of the European Family
      </p>
      <div className="loader__img-wrapper">
        <img src={EuropeFlag} alt="Europe Flag" className="loader__img" />
      </div>
    </div>
  )
}

Loader.propTypes = {
  show: PropTypes.bool,
}

Loader.defaultProps = {
  show: true,
}

export default Loader
